// Add your JS customizations here
jQuery(document).ready(function($) { 

    $(window).scroll(function () {
        console.log('hey yo');
        $('header').toggleClass('scroll', ($(window).scrollTop() > 0));
    });

    $('.nav-link').on('click', function (event) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top + -85
        }, 300);
    });
})